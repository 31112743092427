<template>
  <div>
    <div
      class="flix-bookingCalendar"
      v-show="userID"
      ref="calendar"
      :data-user="userID"
      :data-id="$route.params.id"
    >
      <flixLoader />
    </div>
    <flixLoader v-if="!userID" />
  </div>
</template>
<script>
import { changeLayout } from '@/components/bookingPage/changeLayout.js'
export default {
  components: {},
  props: {},
  data() {
    return {
      userID: false
    }
  },
  methods: {
    getLoadCalendar() {
      var cl = new changeLayout()
      cl.lang = this.$route.params.lang
      cl.run()
    },
    getData() {
      this.$store.dispatch('getBookingPage', {
        username: this.$route.params.username,
        ID: this.$route.params.id,
        callback: function (ret) {
          this.userID = ret[1]
          this.getLoadCalendar()
        }.bind(this)
      })
    }
  },
  mounted() {
    this.getData()
  },
  computed: {}
}
</script>
<style lang="sass" scoped></style>
